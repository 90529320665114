"use client";

import Image from "next/image";

import Picture1 from "@/public/png/home/packages-based/picture-1.jpg";
import Picture2 from "@/public/png/home/packages-based/picture-2.jpg";
import Picture3 from "@/public/png/home/packages-based/picture-3.jpg";
import Picture4 from "@/public/png/home/packages-based/picture-4.jpg";
import ViewMoreIcon from "@/public/svg/home/phone-call.svg";
import PaginationLeft from "@/public/svg/paginarition-arrow-left.svg";
import PaginationRight from "@/public/svg/paginarition-arrow-right.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "@/styles/common/swiper.scss";
import "@/styles/common/typo.scss";

import Title from "@/components/title/Title";
import Heading from "@/components/heading/heading";

import "./packages-based.scss";

const Packages = [
    {
        name: "Honeymoon",
        picture: Picture1,
        note: "Starting from ₹20,000",
        path: "#",
        getInTouch: true
    },
    {
        name: "Family",
        picture: Picture2,
        note: "Starting from ₹18,500",
        path: "#",
        getInTouch: true
    },
    {
        name: "Budget Tours",
        picture: Picture3,
        note: "Starting from ₹16,000",
        path: "#",
        getInTouch: true
    },
    {
        name: "Group Tours",
        picture: Picture4,
        note: "Starting from ₹45,000",
        path: "/malaysia-group-tour",
        getInTouch: false,
    },

];

const PackagesBased = () => {
    return (
        <section className="packages-based-section py-10 md:py-20">
            <div className="packages-based-container container px-4 flex flex-col mx-auto gap-6 sm:gap-14">
                <Title title="Tailored Experiences" />
                <div className="packages-based-slider">
                    <Swiper
                        spaceBetween={24}
                        slidesPerView={1}
                        navigation={{
                            nextEl: ".pagination-right-packaged-based",
                            prevEl: ".pagination-left-packaged-based",
                            enabled: true,
                        }}
                        modules={[Navigation, Autoplay]}
                        autoplay={{
                            delay: 1000,
                            waitForTransition: true,
                        }}
                        loop={true}
                        breakpoints={{
                            639: {
                                slidesPerView: 2,
                            },
                            1023: {
                                slidesPerView: 3,
                            },
                            1279: {
                                slidesPerView: 4,
                            },
                        }}
                        className="packages-swiper"
                    >
                        {Packages.map((p, index) => {
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="packages-slide"
                                >
                                    <a
                                        href={p.path}
                                        className="inline-flex flex-col gap-6 p-6"
                                    >
                                        <figure>
                                            <Image loading="lazy"
                                                src={p.picture}
                                                alt={p.name}

                                            />
                                            <figcaption className={`flex-center p-4 gap-2 ${p.getInTouch ? "" : "hidden md:hidden"}`}>
                                                
                                                <Image loading="lazy"
                                                    className=""
                                                    src={ViewMoreIcon}
                                                    alt="Get in Touch"
                                                    width={24}
                                                    height={24}

                                                />
                                                <span className="font-bold text-white">
                                                    Get in Touch
                                                </span>
                                               
                                            </figcaption>
                                        </figure>
                                        <div className="packages-info flex flex-col gap-2 pt-6">
                                            <Heading as="h6">{p.name}</Heading>
                                            <span className="font-light">
                                                {p.note}
                                            </span>
                                        </div>
                                    </a>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div className="swiper-pagination-wrapper flex items-center justify-end gap-2 mt-4 sm:mt-10">
                        <div className="pagination-arrows flex items-center gap-3 sm:gap-4">
                            <figure className="pagination-left-packaged-based flex-center">
                                <Image loading="lazy"
                                    src={PaginationLeft}
                                    alt="Pagination Left"

                                />
                            </figure>
                            <figure className="pagination-right-packaged-based flex-center">
                                <Image loading="lazy"
                                    src={PaginationRight}
                                    alt="Pagination Right"

                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PackagesBased;
